import React, { useState } from "react";
import {
  Container,
  Box,
  Button,
  Typography,
  TextField,
  Checkbox,
  FormControlLabel,
  FormControl,
  RadioGroup,
  FormLabel,
  Radio,
  IconButton,
} from "@material-ui/core";
import { Autorenew } from "@material-ui/icons";

import * as common from "jeoparty-common";

const getRandomSessionId = () => {
  const randomWords = require("random-words");
  return randomWords({
    exactly: 1,
    wordsPerString: 2,
    separator: "-",
  })[0];
};

type WelcomeProps = {
  userType: common.GUIUserType;
  sessionId: string;
  player?: string;
  teamName?: string;
  goCallback: (
    session: string,
    userType: common.GUIUserType,
    player?: string,
    team?: string
  ) => void;
};

export default function Welcome(props: WelcomeProps) {
  let [session, setSession] = useState(props.sessionId || getRandomSessionId());
  let [userType, setUserType] = useState(props.userType);
  let [player, setPlayer] = useState(props.player);
  let [team, setTeam] = useState(props.teamName);
  let [teamEnabled, setTeamEnabled] = useState(player !== team); // if they are the same, we hide the field by default

  const isPlayer = userType === common.GUIUserType.PLAYER;

  const readyToGo =
    session &&
    userType &&
    ((isPlayer && player && (!teamEnabled || (teamEnabled && team))) ||
      !isPlayer);

  const handleRadioSelect = (e: React.ChangeEvent<HTMLInputElement>) => {
    const userType: common.GUIUserType = parseInt(e.target.value);
    setUserType(userType);
    switch (userType) {
      case common.GUIUserType.PLAYER:
        break;

      case common.GUIUserType.ADMIN: //fallthrough
      case common.GUIUserType.OBSERVER: //fallthough
      case common.GUIUserType.UNDEFINED: //fallthrough
      default:
        setPlayer(undefined);
        setTeam(undefined);
        setTeamEnabled(false);
    }
  };

  return (
    <Container maxWidth="sm" style={{ alignContent: "center" }}>
      <Box>
        <Typography variant="h4" color="primary" align="center">
          Jeoparty!
        </Typography>
      </Box>
      <Box paddingTop={3}>
        <TextField
          fullWidth
          margin="normal"
          label="Game name"
          variant="outlined"
          placeholder="asdf"
          value={session}
          onChange={(e) => setSession(e.target.value)}
          InputLabelProps={{ shrink: !!session }} //https://material-ui.com/components/text-fields/#shrink
          InputProps={{
            endAdornment: (
              <IconButton
                aria-label="generate"
                onClick={() => setSession(getRandomSessionId)}
              >
                <Autorenew />
              </IconButton>
            ),
          }}
        ></TextField>
      </Box>

      <Box paddingTop={3}>
        <FormControl component="fieldset">
          <FormLabel component="legend">Choose your role</FormLabel>
          <RadioGroup
            aria-label="role"
            name="role"
            value={userType}
            onChange={handleRadioSelect}
          >
            <FormControlLabel
              value={common.GUIUserType.PLAYER}
              control={<Radio />}
              label="Play"
            />
            <FormControlLabel
              value={common.GUIUserType.OBSERVER}
              control={<Radio />}
              label="Watch"
            />
            <FormControlLabel
              value={common.GUIUserType.ADMIN}
              control={<Radio />}
              label="Admin"
            />
          </RadioGroup>
        </FormControl>
      </Box>

      {userType === common.GUIUserType.PLAYER && (
        <Box paddingTop={3}>
          <TextField
            fullWidth
            margin="normal"
            label="Player name"
            variant="outlined"
            placeholder="Ken Jennings"
            value={player}
            onChange={(e) => setPlayer(e.target.value)}
          ></TextField>

          <FormControlLabel
            control={
              <Checkbox
                checked={teamEnabled}
                onChange={(e) => {
                  setTeamEnabled(e.target.checked);

                  // if (e.target.checked === false) {
                  //   setTeam(undefined);
                  // }
                }}
                name="checkedB"
                color="primary"
              />
            }
            label="Join a team (optional)"
          />

          {teamEnabled && (
            <TextField
              fullWidth
              margin="normal"
              label="Team name"
              variant="outlined"
              placeholder="My Awesome Team"
              value={team}
              onChange={(e) => setTeam(e.target.value)}
            ></TextField>
          )}
        </Box>
      )}

      <Box paddingTop={3}>
        <Button
          variant="contained"
          color="primary"
          onClick={() => props.goCallback(session, userType, player, team)}
          disabled={!readyToGo}
        >
          Go!
        </Button>
      </Box>
    </Container>
  );
}
