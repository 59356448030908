import React, { useState } from "react";
import { Button, Box, TextField } from "@material-ui/core";

type WagerProps = {
  submitWager: (wager: number) => void;
  validateWager: (wager: number) => string | undefined;
};

export default function Wager({ submitWager, validateWager }: WagerProps) {
  const [hasSubmitted, setHasSubmitted] = useState(false);
  const [wager, setWager] = useState(0);
  const [displayWager, setDisplayWager] = useState("");

  const updateWagers = (wager: string) => {
    setWager(Number(wager));
    setDisplayWager(wager);
  };

  const sendWager = () => {
    if (!hasSubmitted) {
      submitWager(wager);
    }
    setHasSubmitted(true);
  };

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
    >
      <TextField
        fullWidth
        margin="normal"
        label="Wager Amount"
        type="number"
        variant="outlined"
        error={validateWager(wager) ? true : false}
        helperText={validateWager(wager)}
        value={displayWager}
        onChange={(e) => updateWagers(e.target.value)}
      ></TextField>
      <Button
        variant="outlined"
        color="primary"
        onClick={sendWager}
        disabled={validateWager(wager) ? true : false}
      >
        Submit
      </Button>
    </Box>
  );
}
