export interface GameState {
  // Apparently if your file is not just interfaces then TS doesn't auto import?
  gameId: number;
  airdate: string;
  showNumber: number;

  singleJeopardy: JeopardyBoard;
  doubleJeopardy: JeopardyBoard;
  finalJeopardy: JeopardyBoard;

  contestants: Contestants;

  currentRound: Round;
  currentQuestion: number;

  buzzerOpen: boolean;
  buzzedInTeam: string;
  buzzedInPlayer: string;

  teamInControl: string;

  dailyDoubleWager: number; // cleanup

  finalJeopardyWagersOpen: boolean;
  submissionsOpen: boolean;
}

export interface Contestant {
  score: number;
  players: string[];
  dailyDoubleWager: number;
  finalJeopardyWager: number;
  submission: string;
  revealed: boolean;
  buzzInTime: number;
}

export interface Contestants {
  [contestantName: string]: Contestant;
}

export enum Round {
  SINGLE,
  DOUBLE,
  FINAL,
  DONE,
}

export enum Status {
  UNPLAYED,
  INPLAY,
  PLAYED,
}

export interface JeopardyBoard {
  categories: string[];
  clues: Clue[][];
  round: Round;
}

export interface Clue {
  question: string;
  answer: string;
  value: number;
  dailyDouble: boolean;
  media: boolean;
  status: Status;
}

export const NO_QUESTION = -1;
export const NO_BUZZ_IN_TIME = -1;
export const NO_DAILY_DOUBLE_WAGER = -1;
export const NO_FINAL_JEOPARDY_WAGER = -1;
export const NO_FINAL_JEOPARDY_SUBMISSION = "___no submission yet___";

export enum GUIUserType {
  UNDEFINED,
  ADMIN,
  PLAYER,
  OBSERVER,
}
