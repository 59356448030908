import React, { useState } from "react";

import { Button, PropTypes } from "@material-ui/core";

type BuzzerProps = {
  isAdmin: boolean;
  buzzerOpen: boolean;
  apiClientBuzz: () => void;
  openBuzzer: () => void;
  openClue: boolean;
};

// !currentClue || isSpectator || (!this.state.buzzerOpen && !isAdmin) || (this.state.buzzerOpen && isAdmin)

export default function LockOutBuzzer({
  isAdmin,
  buzzerOpen,
  apiClientBuzz,
  openBuzzer,
  openClue,
}: BuzzerProps) {
  const [isLocked, setLocked] = useState(false);
  // const [timeoutID, setTimeoutID] = useRef(null)
  const [timeoutID, setTimeoutID] = useState(0);

  const buzzIn = () => {
    if (buzzerOpen) {
      if (!isLocked) {
        apiClientBuzz();
      }
    } else {
      lockBuzzer();
    }
  };

  const lockBuzzer = () => {
    setLocked(true);

    if (isLocked) {
      window.clearTimeout(timeoutID);
    }

    const to = window.setTimeout(() => {
      setLocked(false);
    }, 1000); // 1s
    setTimeoutID(to);
    setLocked(true);
  };

  let buzzerColor: PropTypes.Color = "default";
  let buzzerString;

  if (isLocked) {
    buzzerColor = "secondary";
    buzzerString = "Buzz (locked)";
  } else if (buzzerOpen) {
    buzzerColor = "primary";
    buzzerString = "Buzz (open)";
  } else {
    buzzerColor = "default";
    buzzerString = "Buzz";
  }

  // "Buzzer Closed" = Colour1 --> "Buzzer Open" = Colour2 / "Buzzer Locked" = Colour3
  return (
    <>
      {!isAdmin && (
        <Button
          variant="contained"
          color={buzzerColor}
          onMouseDown={buzzIn}
          size="large"
          fullWidth
          disableRipple
        >
          {buzzerString}
        </Button>
      )}
      {isAdmin && (
        <Button
          variant="contained"
          color="primary"
          onClick={openBuzzer}
          disabled={!openClue || buzzerOpen}
          size="large"
          fullWidth
          disableRipple
        >
          {buzzerOpen ? "Buzzer" : "Open Buzzer"}
        </Button>
      )}
    </>
  );
}
