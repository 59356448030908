import React from "react";
import {
  Button,
  Card,
  CardActions,
  CardContent,
  Grid,
  Typography,
} from "@material-ui/core";
import * as common from "jeoparty-common";

type FinalJeopardyRevealProps = {
  contestants?: common.Contestants;
  modifyScore: (teamName: string, value: number) => void;
  isAdmin: boolean;
  reveal: (teamName: string) => void;
  submissionsOpen: boolean;
  wageringOpen: boolean;
};

export default function FinalJeopardyReveal({
  contestants,
  modifyScore,
  isAdmin,
  reveal,
  submissionsOpen,
  wageringOpen,
}: FinalJeopardyRevealProps) {
  if (!contestants) return null;

  const teamNames = Object.keys(contestants);

  // Sort by score in ascending order
  teamNames.sort((t1, t2) => {
    return contestants[t1].score - contestants[t2].score;
  });

  return (
    <Grid container spacing={2}>
      {teamNames.map((name) => {
        let wager = contestants[name].finalJeopardyWager;
        let answer = contestants[name].submission;
        const revealed = contestants[name].revealed;

        answer =
          answer !== common.NO_FINAL_JEOPARDY_SUBMISSION ? answer : "No answer";
        wager = wager !== common.NO_FINAL_JEOPARDY_WAGER ? wager : 0;

        return (
          <Grid item xs={6} sm={4} md={2} key={name}>
            <Card key={name}>
              <CardContent>
                <Typography variant="h6" color="primary">
                  {name}
                </Typography>
                {isAdmin &&
                  !revealed &&
                  contestants[name]?.finalJeopardyWager !==
                    common.NO_FINAL_JEOPARDY_WAGER && (
                    <Typography>Wager submitted</Typography>
                  )}
                {isAdmin &&
                  !revealed &&
                  contestants[name]?.submission !==
                    common.NO_FINAL_JEOPARDY_SUBMISSION && (
                    <Typography>Answer submitted</Typography>
                  )}
                {revealed && (
                  <Typography variant="body1"> Answer: {answer} </Typography>
                )}
                {revealed && (
                  <Typography variant="body1"> Wager: {wager}</Typography>
                )}
              </CardContent>
              <CardActions>
                {isAdmin && (
                  <Button
                    variant="outlined"
                    onClick={() => reveal(name)}
                    disabled={submissionsOpen || wageringOpen}
                  >
                    Reveal
                  </Button>
                )}
                {isAdmin && revealed && (
                  <>
                    <Button
                      color="secondary"
                      onClick={() => modifyScore(name, -wager)}
                    >
                      Incorrect
                    </Button>
                    <Button
                      color="primary"
                      onClick={() => modifyScore(name, wager)}
                    >
                      Correct
                    </Button>
                  </>
                )}
              </CardActions>
            </Card>
          </Grid>
        );
      })}
    </Grid>
  );
}
