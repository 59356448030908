import React, { useEffect, useState } from "react";

import Welcome from "./Welcome";
import Game from "./Game";

import {
  CssBaseline,
  Container,
  FormControl,
  Grid,
  MenuItem,
  Select,
  Typography,
} from "@material-ui/core";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import { blue, orange, indigo } from "@material-ui/core/colors";

import * as common from "jeoparty-common";

type ClientState = Partial<common.GameState>;

// https://material-ui.com/customization/theming/
// https://material.io/resources/color/
const muiTheme = createTheme({
  palette: {
    type: "dark",
    primary: {
      main: orange[500],
    },
    secondary: {
      main: blue[400],
    },
    info: {
      main: indigo[800],
    },
    background: {
      paper: "#343970",
    },
  },
  props: {
    MuiTypography: {
      variantMapping: {
        h4: "h1",
        h5: "h2",
        h6: "h3",
      },
    },
  },
  typography: {
    fontSize: 15,
    fontFamily: "Public Sans",
  },
});

function parseURL(url: URL) {
  const urlPath = url.pathname;
  const urlParams = url.searchParams;
  const splitURL = urlPath.split("/");
  const session = splitURL[1];

  let userType: common.GUIUserType = common.GUIUserType.UNDEFINED;
  let player: string | undefined = undefined;
  let team: string | undefined = undefined;
  if (session !== "") {
    // parse team and player params
    switch (splitURL[2]) {
      case "admin":
        userType = common.GUIUserType.ADMIN;
        break;
      case "play":
        // parse out team and player names
        userType = common.GUIUserType.PLAYER;
        player = urlParams.get("player") || undefined;
        team = urlParams.get("team") || player;
        break;
      case "watch":
        userType = common.GUIUserType.OBSERVER;
        break;
    }
  }
  return { session, userType, player, team };
}

function updateURL(
  session: string,
  userType: common.GUIUserType,
  player?: string,
  team?: string
) {
  const url = new URL(window.location.toString());
  url.pathname = "/" + session;

  switch (userType) {
    case common.GUIUserType.ADMIN:
      url.pathname += "/" + "admin";
      break;
    case common.GUIUserType.PLAYER:
      url.pathname += "/" + "play";
      break;
    case common.GUIUserType.OBSERVER:
      url.pathname += "/" + "watch";
      break;
  }

  if (player) {
    url.searchParams.set("player", player);
  }
  if (team) {
    url.searchParams.set("team", team);
  }

  const historyState = { session, userType, player, team };
  console.log(
    "pushing history state with new url",
    url.toString(),
    "and state object",
    historyState
  );
  window.history.pushState(historyState, "", url.toString());
}

export default function App() {
  const parsedURL = parseURL(new URL(window.location.toString()));

  // define state
  const [session, setSession] = useState<string>(parsedURL.session);
  const [userType, setUserType] = useState<common.GUIUserType>(
    parsedURL.userType
  );
  const [player, setPlayer] = useState<string | undefined>(parsedURL.player);
  const [team, setTeam] = useState<string | undefined>(
    parsedURL.team || parsedURL.player
  ); // behavior with no team is undefined, so we fallback on player name

  useEffect(() => {
    // needed if user goes back or forward
    window.addEventListener("popstate", (event) => {
      console.log("window popstate at location: " + document.location);

      if (event.state) {
        // use state that we earlier provided to history.pushState earlier if it exists
        console.log("popstate state: " + JSON.stringify(event.state));
        setSession(event.state.session);
        setUserType(event.state.userType);
        setPlayer(event.state.player);
        setTeam(event.state.team || event.state.player);
      } else {
        // otherwise parse the url again
        const parsedURL = parseURL(new URL(window.location.toString()));
        setSession(parsedURL.session);
        setUserType(parsedURL.userType);
        setPlayer(parsedURL.player);
        setTeam(parsedURL.team || parsedURL.player);
      }
    });
  });

  const updateAndGo = (
    sessionArg: string,
    userTypeArg: common.GUIUserType,
    playerArg?: string,
    teamArg?: string
  ) => {
    setSession(sessionArg);
    setUserType(userTypeArg);
    setPlayer(playerArg);
    setTeam(teamArg || playerArg); // behavior with no team is undefined, so we fallback on player name
    updateURL(sessionArg, userTypeArg, playerArg, teamArg);
  };

  const readyToPlay =
    session &&
    session !== "" &&
    userType &&
    ((userType === common.GUIUserType.PLAYER && player && team) ||
      userType === common.GUIUserType.ADMIN ||
      userType === common.GUIUserType.OBSERVER);

  return (
    <ThemeProvider theme={muiTheme}>
      <CssBaseline />
      <Container maxWidth="xl" style={{ padding: 20 }}>
        {!readyToPlay && (
          <Welcome
            sessionId={session}
            userType={userType}
            player={player}
            teamName={team}
            goCallback={updateAndGo}
          />
        )}
        {readyToPlay && (
          <Game
            sessionId={session}
            userType={userType}
            player={player}
            teamName={team}
          />
        )}
      </Container>
    </ThemeProvider>
  );
}
