import React, { useState } from "react";

import { IconButton, Snackbar } from "@material-ui/core";
import ShareIcon from "@material-ui/icons/Share";
import MuiAlert, { AlertProps } from "@material-ui/lab/Alert";

function Alert(props: AlertProps) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

type ShareGameProps = {
  shareableLink: string;
};

export default function ShareGame({ shareableLink }: ShareGameProps) {
  const [open, setOpen] = useState(false);

  const handleClick = () => {
    setOpen(true);
  };

  const handleClose = (event?: React.SyntheticEvent, reason?: string) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  return (
    <>
      <IconButton
        aria-label="share"
        size="small"
        onClick={() => {
          navigator.clipboard.writeText(shareableLink);
          handleClick();
        }}
      >
        <ShareIcon />
      </IconButton>
      <Snackbar open={open} autoHideDuration={3000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="info">
          Link copied!
        </Alert>
      </Snackbar>
    </>
  );
}
