import * as common from "jeoparty-common";

type ClientState = any;
type Question = any;
type StateFunction = any;

const trebekTeam = "___trebekTeam___";
const trebekPlayer = "___trebekPlayer___";

const spectatorTeam = "___spectatorTeam___";
const spectatorPlayer = "___spectatorPlayer___";

export default class ApiClient {
  id: string;
  socket: any;
  player: string;
  teamName: string;
  isAdmin: boolean;
  lockedOut: boolean; // penalize early buzzing
  buzzerOpenTime: number;
  gameList: string[];
  setClientState: StateFunction;

  constructor(
    setState: StateFunction,
    anAdmin: boolean,
    sessionId: string,
    teamName?: string,
    player?: string
  ) {
    this.setClientState = setState;
    // register message handler for onmessage in the caller
    this.id = sessionId;
    this.lockedOut = false;
    this.isAdmin = anAdmin;
    this.buzzerOpenTime = 0;
    this.player = player ? player : anAdmin ? trebekPlayer : spectatorPlayer;
    this.teamName = teamName ? teamName : anAdmin ? trebekTeam : spectatorTeam;
    this.gameList = [];
  }

  addSocket = (socket: any): void => {
    this.socket = socket;
  };

  onSocketOpen = (event: Event): void => {
    console.log("Successfully opened websocket");
    if (this.isAdmin) {
      this.sendMessage(new common.TrebekJoinMessage(this.id));
    } else {
      // If no team name or player name is known at time of sockets creation
      // this client is joining as a spectator
      if (this.isSpectator()) {
        this.sendMessage(new common.ContestantSpectate(this.id));
      } else {
        this.sendMessage(
          new common.ContestantJoin(this.id, this.teamName, this.player)
        );
      }
    }
  };

  onSocketMessage = (
    event: MessageEvent,
    currentClientState: ClientState
  ): ClientState => {
    // TODO: might not need to pass in state
    // TODO: Add types to the input parameters

    let message = JSON.parse(event.data);

    console.debug(
      "Received event from jeoparty server of type",
      common.ServerMessageType[message.type]
    );

    switch (message.type) {
      case common.ServerMessageType.DAILY_DOUBLE_WAGER:
        this.updateState(message.newState, currentClientState);
        break;
      case common.ServerMessageType.OPEN_BUZZER:
        this.buzzerOpenTime = Date.now();
        this.updateState(message.newState, currentClientState);
        break;
      case common.ServerMessageType.STATE_UPDATE:
        this.updateState(message.newState, currentClientState);
        break;
      case common.ServerMessageType.GAMES_LIST:
        this.updateGameList(message.games);
        break;
      case common.ServerMessageType.ERROR:
        console.warn("Error from server: ", message.error);
    }
  };

  updateState = (stateUpdate: StateFunction, clientState: ClientState) => {
    this.setClientState({ ...clientState, ...stateUpdate });
  };

  updateGameList = (gameList: string[]) => {
    console.log("Received list of games: " + gameList);
    this.gameList = gameList.reverse();
  };

  onSocketError = (event: Event): void => {
    console.error(event);
  };

  onSocketClose = (event: CloseEvent): void => {
    console.log("Closing connection with jeoparty server");
  };

  // eg. sendMessage(new ModifyScoreMessage("game_id", -1000, "isaac"))
  sendMessage(msg: common.ClientMessage) {
    this.socket.send(JSON.stringify(msg));
  }

  isSpectator = (): boolean => {
    return this.player === spectatorPlayer && this.teamName === spectatorTeam;
  };

  buzz = () => {
    const responseTime: number = Date.now() - this.buzzerOpenTime;
    this.sendMessage(
      new common.ContestantBuzzIn(this.id, this.teamName, responseTime)
    );
  };

  openBuzzer = () => {
    if (this.isAdmin) {
      this.sendMessage(new common.OpenBuzzer(this.id));
    }
  };

  setTeamName = (teamName: string) => {
    this.teamName = teamName;
  };

  setPlayerName = (playerName: string) => {
    this.player = playerName;
  };

  contestantRejoin = () => {
    this.sendMessage(
      new common.ContestantJoin(this.id, this.teamName, this.player)
    );
  };

  selectClue = (clueId: number) => {
    if (this.isAdmin) {
      this.sendMessage(new common.SelectClueMessage(this.id, clueId));
    }
  };

  closeClue = () => {
    if (this.isAdmin) {
      this.sendMessage(new common.CloseClueMessage(this.id));
    }
  };

  modifyScore = (team: string, delta: number) => {
    if (this.isAdmin) {
      this.sendMessage(new common.ModifyScoreMessage(this.id, delta, team));
    }
  };
  setScore = (team: string, newScore: number) => {
    if (this.isAdmin) {
      console.log("setting score for team", team, newScore);
      this.sendMessage(new common.SetScoreMessage(this.id, newScore, team));
    }
  };

  sendWager = (wager: number) => {
    this.sendMessage(new common.ContestantWager(this.id, this.teamName, wager));
  };

  closeWagers = () => {
    if (this.isAdmin) {
      this.sendMessage(new common.CloseWagersMessage(this.id));
    }
  };

  closeSubmissions = () => {
    if (this.isAdmin) {
      this.sendMessage(new common.CloseSubmissionsMessage(this.id));
    }
  };

  sendSubmission = (submission: string) => {
    this.sendMessage(
      new common.ContestantSubmission(this.id, this.teamName, submission)
    );
    console.log("submitted");
  };

  reveal = (team: string) => {
    if (this.isAdmin) {
      this.sendMessage(new common.RevealMessage(this.id, team));
    }
  };

  selectGame = (game: string) => {
    this.sendMessage(new common.SelectGameMessage(this.id, game));
    console.log("Chose game ", game);
  };

  removeContestant = (teamName: string) => {
    if (this.isAdmin) {
      this.sendMessage(new common.ContestantRemoveMessage(this.id, teamName));
    }
  };

  //     shutDown (): void => {
  //         if (this.socket.OPEN) {
  //             this.socket.send("Olivia is leaving the party");
  //             this.socket.close(1000);
  //         }
  //     }
}
