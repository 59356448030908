import React from "react";
import { Button, Card, CardContent, Typography } from "@material-ui/core";
import * as common from "jeoparty-common";
import Wager from "./Wager";
import Submission from "./Submission";

type FinalJeopardyProps = {
  finalJeopardyBoard: common.JeopardyBoard;
  team: common.Contestant;
  isAdmin: boolean;
  isSpectator: boolean;
  submitWager: (wager: number) => void;
  submitFinal: (submission: string) => void;
  closeWagers: () => void;
  closeSubmissions: () => void;
  currentScore: number;
  wagersOpen: boolean;
  submissionsOpen: boolean;
};

export default function FinalJeopardy({
  finalJeopardyBoard,
  team,
  isAdmin,
  isSpectator,
  submitWager,
  submitFinal,
  currentScore,
  wagersOpen,
  closeWagers,
  closeSubmissions,
  submissionsOpen,
}: FinalJeopardyProps) {
  // TOOD: Better error handling when there are no contestants

  let checkFinalJeopardyWager = (wager: number) => {
    if (wager > currentScore) {
      return "Maximum wager is " + currentScore;
    } else if (wager < 0) {
      return "You must wager some money.";
    }
  };

  const canShowAnswer = !wagersOpen && isAdmin; // TODO reveal if all contestants answers are revealed

  // TODO: Consider centering submit/wager diaglogue on form
  return (
    <Card>
      <CardContent>
        <Typography variant="h6" color="primary">
          {finalJeopardyBoard.categories[0]}
        </Typography>
        {/* question */}
        {!wagersOpen && (
          <Typography variant="subtitle1">
            {finalJeopardyBoard.clues[0][0].question}
          </Typography>
        )}

        {/* answer */}
        {canShowAnswer && (
          <Typography variant="subtitle1" color="textSecondary">
            <em>{finalJeopardyBoard.clues[0][0].answer}</em>
          </Typography>
        )}

        {/* wager */}
        {!isAdmin &&
          !isSpectator &&
          team &&
          team.finalJeopardyWager === common.NO_FINAL_JEOPARDY_WAGER &&
          wagersOpen && (
            <Wager
              submitWager={submitWager}
              validateWager={checkFinalJeopardyWager}
            />
          )}

        {/*  submission */}
        {!isAdmin && !isSpectator && team && submissionsOpen && (
          <Submission submit={submitFinal} />
        )}

        {isAdmin && wagersOpen && (
          <Button variant="contained" color="primary" onClick={closeWagers}>
            Close Wagering Period
          </Button>
        )}
        {isAdmin && !wagersOpen && submissionsOpen && (
          <Button
            variant="contained"
            color="primary"
            onClick={closeSubmissions}
          >
            Close Submission Period
          </Button>
        )}
      </CardContent>
    </Card>
  );
}
