import { GameState } from "../stateTypes";

export enum ServerMessageType {
  OPEN_BUZZER,
  STATE_UPDATE,
  DAILY_DOUBLE_WAGER,
  ERROR,
  GAMES_LIST,
}

export class ServerMessage {
  type: ServerMessageType;

  constructor(type: ServerMessageType) {
    this.type = type;
  }
}

export class StateUpdateMessage extends ServerMessage {
  newState: GameState;

  constructor(newState: GameState) {
    super(ServerMessageType.STATE_UPDATE);
    this.newState = newState;
  }
}

export class OpenBuzzerMessage extends ServerMessage {
  newState: GameState;

  constructor(newState: GameState) {
    super(ServerMessageType.OPEN_BUZZER);
    this.newState = newState;
  }
}

export class DailyDoubleWagerMessage extends ServerMessage {
  newState: GameState;

  constructor(newState: GameState) {
    super(ServerMessageType.DAILY_DOUBLE_WAGER);
    this.newState = newState;
  }
}

export class ErrorMessage extends ServerMessage {
  error: string;

  constructor(message: string) {
    super(ServerMessageType.ERROR);
    this.error = message;
  }
}

export class GamesListMessage extends ServerMessage {
  games: string[];

  constructor(games: string[]) {
    super(ServerMessageType.GAMES_LIST);
    this.games = games;
  }
}
