import React from "react";

import ApiClient from "./apiClient";
import Buzzer from "./Buzzer";
import Board from "./Board";
import Clue from "./Clue";
import Contestants from "./Contestants";
import FinalJeopardy from "./FinalJeopardy";
import FJR from "./FinalJeopardyReveal";
import ShareGame from "./ShareGame";

import {
  FormControl,
  Grid,
  MenuItem,
  Select,
  Typography,
} from "@material-ui/core";

import * as common from "jeoparty-common";

type ClientState = Partial<common.GameState>;

const isLocalhost = Boolean(
  window.location.hostname === "localhost" ||
    // [::1] is the IPv6 localhost address.
    window.location.hostname === "[::1]" ||
    // 127.0.0.1/8 is considered localhost for IPv4.
    window.location.hostname.match(
      /^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/
    )
);

type GameProps = {
  userType: common.GUIUserType;
  sessionId: string;
  player?: string;
  teamName?: string;
};
export default class Game extends React.Component<GameProps, ClientState> {
  apiClient: ApiClient;

  // TODO: Because of routing memory leak introduced by callback to this.setState in apiClient
  //       Follow the below link to fix it
  //       https://www.robinwieruch.de/react-warning-cant-call-setstate-on-an-unmounted-component

  constructor(props: GameProps) {
    super(props);

    this.state = {};

    let anAdmin: boolean = props.userType === common.GUIUserType.ADMIN;

    this.apiClient = new ApiClient(
      this.setState.bind(this),
      anAdmin,
      props.sessionId,
      props.teamName,
      props.player
    );
  }

  // Similar to componentDidMount and componentDidUpdate:
  // Runs when a user first loads page
  componentDidMount() {
    let socket;
    if (isLocalhost) {
      socket = new WebSocket("ws://localhost:8080");
    } else {
      socket = new WebSocket("wss://jeoparty.platypus-map.ts.net/ws");
    }
    this.apiClient.addSocket(socket);

    // This could be moved to the constructor
    // if (!this.apiClient.isAdmin) {
    //   this.apiClient.setTeamName(this.props.match.params.teamName)
    // }

    socket.onopen = this.apiClient.onSocketOpen;
    socket.onmessage = (event) => {
      console.debug("got message");
      this.apiClient.onSocketMessage(event, this.state);
    };
    socket.onerror = this.apiClient.onSocketError;
    socket.onclose = this.apiClient.onSocketClose;
  }

  componentDidUpdate(prevProps: GameProps) {
    if (this.props.teamName && prevProps.teamName !== this.props.teamName) {
      console.debug("did update");
      this.apiClient.setTeamName(this.props.teamName);

      if (this.props.player) {
        this.apiClient.setPlayerName(this.props.player);
      }

      this.apiClient.contestantRejoin();
    }
  }

  render() {
    const isAdmin = this.apiClient.isAdmin;
    const isSpectator = this.apiClient.isSpectator();

    let currentRound;
    if (this.state.currentRound !== undefined) {
      switch (this.state.currentRound) {
        case common.Round.SINGLE:
          currentRound = this.state.singleJeopardy;
          break;
        case common.Round.DOUBLE:
          currentRound = this.state.doubleJeopardy;
          break;
        case common.Round.FINAL:
          currentRound = this.state.finalJeopardy;
          break;
        case common.Round.DONE:
          // TODO: Visualize game finished state
          break;
      }
    }

    let cq = this.state.currentQuestion;
    let currentClue;

    if (currentRound && cq !== undefined && cq !== common.NO_QUESTION) {
      const row = Math.floor(cq / 6);
      const col = cq % 6;
      currentClue = currentRound.clues[row][col];
    }

    let selectClue;
    if (isAdmin && !currentClue) {
      selectClue = this.apiClient.selectClue;
    }

    let roundName;
    switch (this.state.currentRound) {
      case common.Round.SINGLE:
        roundName = "Jeoparty!";
        break;
      case common.Round.DOUBLE:
        roundName = "Double Jeoparty!";
        break;
      case common.Round.FINAL:
        roundName = "FINAL JEOPARTY!!!";
        break;
      default:
        roundName = "";
    }

    let shareableLink = window.location.origin + "/" + this.props.sessionId;

    // sanity checks
    if (isAdmin !== (this.props.userType === common.GUIUserType.ADMIN)) {
      console.error("admin state mismatch");
      return "error";
    }
    if (
      this.props.userType === common.GUIUserType.PLAYER &&
      (!this.props.teamName || !this.props.player)
    ) {
      console.error("team or player should be defined");
      return "error";
    }

    return (
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <Typography variant="h4" component="h1" color="primary">
            {roundName}
          </Typography>
          {!isAdmin && (
            <Typography variant="subtitle1" color="textSecondary">
              {this.state.airdate}&nbsp;
              <ShareGame shareableLink={shareableLink} />
            </Typography>
          )}
          {isAdmin && this.apiClient.gameList.length > 0 && (
            <>
              <FormControl>
                <Select
                  value={this.state.airdate}
                  variant="outlined"
                  onChange={(e: any) =>
                    this.apiClient.selectGame(e.target.value)
                  }
                >
                  {this.apiClient.gameList.map((item) => (
                    <MenuItem value={item} key={item}>
                      {item}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <ShareGame shareableLink={shareableLink} />
            </>
          )}
        </Grid>

        <Grid item md={8} xs={12}>
          {currentRound !== undefined &&
            this.state.currentRound !== common.Round.FINAL && (
              <Board currentRound={currentRound} selectClue={selectClue} />
            )}
          {currentRound !== undefined &&
            this.state.currentRound === common.Round.FINAL &&
            this.state.contestants && (
              <FinalJeopardy
                finalJeopardyBoard={currentRound}
                team={this.state.contestants[this.props.teamName || ""]}
                submitWager={this.apiClient.sendWager}
                submitFinal={this.apiClient.sendSubmission}
                isAdmin={isAdmin}
                isSpectator={isSpectator}
                closeWagers={this.apiClient.closeWagers}
                closeSubmissions={this.apiClient.closeSubmissions}
                wagersOpen={this.state.finalJeopardyWagersOpen || false}
                submissionsOpen={this.state.submissionsOpen || false}
                currentScore={
                  this.state.contestants && !isAdmin && !isSpectator
                    ? this.state.contestants[this.apiClient.teamName].score
                    : 0
                }
              />
            )}
        </Grid>
        <Grid item md={4} xs={12}>
          {!this.apiClient.isSpectator() && (
            <Buzzer
              isAdmin={isAdmin}
              buzzerOpen={this.state.buzzerOpen ? this.state.buzzerOpen : false}
              openClue={!!currentClue}
              apiClientBuzz={this.apiClient.buzz}
              openBuzzer={this.apiClient.openBuzzer}
            />
          )}
          {currentClue !== undefined && (
            <Clue
              currentClue={currentClue}
              isAdmin={isAdmin}
              buzzedInTeam={
                this.state.buzzedInTeam ? this.state.buzzedInTeam : ""
              } //always nonempty
              teamInControl={
                this.state.teamInControl ? this.state.teamInControl : ""
              } //always nonempty
              modifyScore={this.apiClient.modifyScore}
              dailyDoubleWager={
                this.state.dailyDoubleWager
                  ? this.state.dailyDoubleWager
                  : common.NO_DAILY_DOUBLE_WAGER
              }
              closeClue={this.apiClient.closeClue}
              controlsBoard={
                this.apiClient.teamName === this.state.teamInControl
              }
              currentScore={
                this.state.contestants && !isAdmin && !isSpectator
                  ? this.state.contestants[this.apiClient.teamName].score
                  : 0
              }
              submitWager={this.apiClient.sendWager}
            />
          )}
        </Grid>
        {/* If we want to change the team name we should use the router and route to a new URL */}
        <Grid item xs={12}>
          <Contestants
            contestants={this.state.contestants}
            currentClue={currentClue}
            isAdmin={isAdmin}
            modifyScore={this.apiClient.modifyScore}
            setScore={this.apiClient.setScore}
            removeContestant={this.apiClient.removeContestant}
            buzzedInTeam={this.state.buzzedInTeam}
            myTeam={this.props.teamName}
            teamInControl={this.state.teamInControl}
          />
        </Grid>
        {currentRound !== undefined &&
          this.state.currentRound === common.Round.FINAL && (
            <Grid item xs={12}>
              <Typography variant="h5">Final Jeopardy Answers</Typography>

              <FJR
                contestants={this.state.contestants}
                modifyScore={this.apiClient.modifyScore}
                isAdmin={isAdmin}
                reveal={this.apiClient.reveal}
                wageringOpen={this.state.finalJeopardyWagersOpen || false}
                submissionsOpen={this.state.submissionsOpen || false}
              />
            </Grid>
          )}
      </Grid>
    );
  }
}
