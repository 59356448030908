import React from "react";
import {
  Card,
  CardActionArea,
  CardContent,
  Grid,
  Typography,
} from "@material-ui/core";

import * as common from "jeoparty-common";

type BoardClueProps = {
  clue: common.Clue;
  id: number;
  selectClue?: (clueId: number) => void;
};

// TODO: Resize the size of categories since some are too long to fit; make taller

function BoardClue({ clue, id, selectClue }: BoardClueProps) {
  if (!clue) return <Grid key={id} item xs={2}></Grid>;

  type CluePropsType = {
    style: {};
    onClick?: () => void;
  };
  let clueProps: CluePropsType = {
    style: {},
  };

  switch (clue.status) {
    case common.Status.UNPLAYED: // unplayed
      // clueProps.style={}
      break;
    case common.Status.INPLAY: // in play
      clueProps.style = { background: "#6C6CE0" };
      break;
    case common.Status.PLAYED: // played
      clueProps.style = { background: "#333333" };
      break;
  }

  if (selectClue) {
    clueProps = {
      ...clueProps,
      onClick: () => selectClue(id),
    };
  }

  const onClickFunction = selectClue ? () => selectClue(id) : () => {};
  const isDisabled = clue.status === common.Status.PLAYED;
  const isPrimary = clue.status === common.Status.INPLAY;

  let color: any = "primary";
  if (isPrimary) {
    color = undefined;
  }
  return (
    // todo: better handling of final jeopardy
    <Grid item key={id} xs={2} zeroMinWidth>
      <Card style={{ padding: 12 }}>
        <CardActionArea onClick={onClickFunction} disabled={isDisabled}>
          <Typography color={color} align="center">
            <strong>
              {!isDisabled ? clue.value || "Final Jeopardy" : "\xa0"}
            </strong>
          </Typography>
        </CardActionArea>
      </Card>
    </Grid>
  );
}

type BoardProps = {
  currentRound: common.JeopardyBoard;
  selectClue?: (clueId: number) => void;
};

export default function Board({ currentRound, selectClue }: BoardProps) {
  return (
    <Grid container spacing={1} alignItems="stretch">
      {currentRound.categories.map((cat: string) => (
        <Grid item key={cat} xs={2} style={{ marginBottom: 10 }}>
          <Card style={{ height: "100%" }}>
            <CardContent style={{ padding: 20, height: "100%" }}>
              <Typography
                align="center"
                style={{
                  height: "100%",
                  display: "flex",
                  justifyContent: "center",
                  placeItems: "center",
                }}
              >
                <strong>{cat}</strong>
              </Typography>
            </CardContent>
          </Card>
        </Grid>
      ))}

      {currentRound.clues.map((row: common.Clue[], row_number: number) =>
        row.map((clue: common.Clue, col_number: number) => {
          const id = 6 * row_number + col_number;
          return (
            <BoardClue clue={clue} id={id} key={id} selectClue={selectClue} />
          );
        })
      )}
    </Grid>
  );
}
