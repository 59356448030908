import React, { useState } from "react";
import { Button, Box, TextField, Snackbar } from "@material-ui/core";
import MuiAlert, { AlertProps } from "@material-ui/lab/Alert";

function Alert(props: AlertProps) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

type SubmissionProps = {
  submit: (submission: string) => void;
};

export default function Submission({ submit }: SubmissionProps) {
  const [submission, setSubmission] = useState("");
  const [snackbarOpen, setSnackbarOpen] = useState(false);

  const onClickHandler = () => {
    submit(submission);
    setSnackbarOpen(true);
  };

  const handleClose = (event?: React.SyntheticEvent, reason?: string) => {
    if (reason === "clickaway") {
      return;
    }

    setSnackbarOpen(false);
  };

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
    >
      <TextField
        fullWidth
        margin="normal"
        label="Final Jeopardy Answer"
        variant="outlined"
        placeholder={"Enter answer"}
        value={submission}
        onChange={(e) => setSubmission(e.target.value)}
      ></TextField>
      <Button variant="outlined" color="primary" onClick={onClickHandler}>
        Submit
      </Button>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={1500}
        onClose={handleClose}
      >
        <Alert onClose={handleClose} severity="info">
          Submitted
        </Alert>
      </Snackbar>
    </Box>
  );
}
