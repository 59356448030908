// This file describes the messages that the Client can send to the Server

export enum ClientMessageType {
  START_GAME,
  SELECT_CLUE,
  TREBEK_JOIN,
  MODIFY_SCORE,
  SET_SCORE,
  CONTESTANT_JOIN,
  CONTESTANT_BUZZ,
  CONTESTANT_WAGER,
  CONTESTANT_SPECTATE,
  CONTESTANT_SUBMISSION,
  CONTESTANT_REMOVE,
  CLOSE_CLUE,
  CLOSE_SUBMISSIONS,
  CLOSE_WAGERING,
  OPEN_BUZZER,
  REVEAL,
  SELECT_GAME,
}

export class ClientMessage {
  id: string; // eg. A random string like "wgtus"
  type: ClientMessageType;

  constructor(id: string) {
    this.id = id;
  }
}

/* ====================== Trebek Side ================== */

export class TrebekJoinMessage extends ClientMessage {
  constructor(id: string) {
    super(id);
    this.type = ClientMessageType.TREBEK_JOIN;
  }
}

// Tells the server to make a new game with game id <id>
export class StartGameMessage extends ClientMessage {
  airdate: string;

  constructor(id: string, airdate: string) {
    super(id);
    this.type = ClientMessageType.START_GAME;
    this.airdate = airdate;
  }
}

// Tells the server to transition a clue on a jeopardy board to the INPLAY state
export class SelectClueMessage extends ClientMessage {
  clueId: number;

  constructor(id: string, clueId: number) {
    super(id);
    this.clueId = clueId;
    this.type = ClientMessageType.SELECT_CLUE;
  }
}

// Modify Score
export class ModifyScoreMessage extends ClientMessage {
  teamName: string;
  scoreDelta: number;

  constructor(id: string, scoreDelta: number, teamName: string) {
    super(id);
    this.teamName = teamName;
    this.scoreDelta = scoreDelta;
    this.type = ClientMessageType.MODIFY_SCORE;
  }
}

// Set Score
export class SetScoreMessage extends ClientMessage {
  teamName: string;
  newScore: number;

  constructor(id: string, newScore: number, teamName: string) {
    super(id);
    this.teamName = teamName;
    this.newScore = newScore;
    this.type = ClientMessageType.SET_SCORE;
  }
}

// Close Clue
export class CloseClueMessage extends ClientMessage {
  constructor(id: string) {
    super(id);
    this.type = ClientMessageType.CLOSE_CLUE;
  }
}

// Trebek allows contestants to buzz in
export class OpenBuzzer extends ClientMessage {
  constructor(id: string) {
    super(id);
    this.type = ClientMessageType.OPEN_BUZZER;
  }
}

// End current wagering period
export class CloseWagersMessage extends ClientMessage {
  constructor(id: string) {
    super(id);
    this.type = ClientMessageType.CLOSE_WAGERING;
  }
}

// End period where contestants submit responses
export class CloseSubmissionsMessage extends ClientMessage {
  constructor(id: string) {
    super(id);
    this.type = ClientMessageType.CLOSE_SUBMISSIONS;
  }
}

export class RevealMessage extends ClientMessage {
  teamName: string;

  constructor(id: string, teamName: string) {
    super(id);
    this.teamName = teamName;
    this.type = ClientMessageType.REVEAL;
  }
}

// Remove a contestant from a Jeopardy session
export class ContestantRemoveMessage extends ClientMessage {
  teamName: string;

  constructor(id: string, teamName: string) {
    super(id);
    this.teamName = teamName;
    this.type = ClientMessageType.CONTESTANT_REMOVE;
  }
}

/* ==================== Contestant Side ============================ */
export class ContestantBuzzIn extends ClientMessage {
  teamName: string;
  timeToBuzz: number;

  constructor(id: string, teamName: string, timeToBuzz: number) {
    super(id);
    this.teamName = teamName;
    this.timeToBuzz = timeToBuzz;
    this.type = ClientMessageType.CONTESTANT_BUZZ;
  }
}

export class ContestantJoin extends ClientMessage {
  teamName: string;
  playerName: string;

  constructor(id: string, teamName: string, playerName: string) {
    super(id);
    this.teamName = teamName;
    this.playerName = playerName;
    this.type = ClientMessageType.CONTESTANT_JOIN;
  }
}

export class ContestantSpectate extends ClientMessage {
  constructor(id: string) {
    super(id);
    this.type = ClientMessageType.CONTESTANT_SPECTATE;
  }
}

export class ContestantWager extends ClientMessage {
  wager: number;
  teamName: string;

  constructor(id: string, teamName: string, wager: number) {
    super(id);
    this.wager = wager;
    this.teamName = teamName;
    this.type = ClientMessageType.CONTESTANT_WAGER;
  }
}

export class ContestantSubmission extends ClientMessage {
  teamName: string;
  submission: string;

  constructor(id: string, teamName: string, submission: string) {
    super(id);
    this.teamName = teamName;
    this.submission = submission;
    this.type = ClientMessageType.CONTESTANT_SUBMISSION;
  }
}

export class SelectGameMessage extends ClientMessage {
  gameName: string;

  constructor(id: string, gameName: string) {
    super(id);
    this.gameName = gameName;
    this.type = ClientMessageType.SELECT_GAME;
  }
}
