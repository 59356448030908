import React from "react";

import {
  Button,
  Card,
  CardActions,
  CardContent,
  Typography,
} from "@material-ui/core";

import Wager from "./Wager";
import * as common from "jeoparty-common";

type ClueProps = {
  isAdmin: boolean;
  currentClue: common.Clue;
  controlsBoard: boolean;
  currentScore: number;
  buzzedInTeam: string;
  teamInControl: string;
  dailyDoubleWager: number;
  modifyScore: (teamName: string, value: number) => void;
  submitWager: (wager: number) => void;
  closeClue: () => void;
};

//TODO: Safety checks for lifecylce of daily double wagers. Should not be able to assign right or wrong until daily double wager is made
export default function Clue({
  isAdmin,
  currentClue,
  closeClue,
  controlsBoard,
  currentScore,
  submitWager: sendWagerFunc,
  dailyDoubleWager,
  buzzedInTeam,
  modifyScore,
  teamInControl,
}: ClueProps) {
  let checkDailyDoubleWagerFunc = (wager: number) => {
    const maxWager = Math.max(currentScore, currentClue.value);
    if (wager > maxWager) {
      return "Maximum wager is " + maxWager.toString();
    } else if (wager <= 0) {
      return "You must wager some money.";
    }
  };

  // Probably fine to have this be local state, but if a person refreshes
  // the page they will be able to submit again. With the input likely ignored
  const clueValue = currentClue.dailyDouble
    ? dailyDoubleWager
    : currentClue.value;
  const team = currentClue.dailyDouble ? teamInControl : buzzedInTeam;
  const canShowQuestion =
    isAdmin ||
    !currentClue.dailyDouble ||
    (currentClue.dailyDouble &&
      dailyDoubleWager !== common.NO_DAILY_DOUBLE_WAGER);

  return (
    <Card>
      <CardContent>
        {currentClue.dailyDouble && (
          <Typography gutterBottom variant="h5">
            Daily Double!
          </Typography>
        )}
        <Typography gutterBottom variant="h5" color="primary">
          {currentClue.value} points
        </Typography>
        {controlsBoard &&
          currentClue.dailyDouble &&
          dailyDoubleWager === common.NO_DAILY_DOUBLE_WAGER && (
            <Wager
              submitWager={sendWagerFunc}
              validateWager={checkDailyDoubleWagerFunc}
            />
          )}
        {canShowQuestion && (
          <Typography variant="body1" component="p">
            {currentClue.media && "Media clue:"} {currentClue.question}
          </Typography>
        )}
        {isAdmin && (
          <Typography variant="body2" component="p" color="textSecondary">
            Answer: <em>{currentClue.answer}</em>
          </Typography>
        )}
      </CardContent>
      {isAdmin && (
        <CardActions>
          <Button
            color="secondary"
            onClick={() => modifyScore(team, -clueValue)}
            disabled={team === "" || clueValue === common.NO_DAILY_DOUBLE_WAGER}
          >
            Incorrect
          </Button>
          <Button
            color="primary"
            onClick={() => modifyScore(team, clueValue)}
            disabled={team === "" || clueValue === common.NO_DAILY_DOUBLE_WAGER}
          >
            Correct
          </Button>
          <Button
            onClick={closeClue}
            style={{ marginLeft: "auto" }}
            disabled={currentClue.dailyDouble}
          >
            Close
          </Button>
        </CardActions>
      )}
    </Card>
  );
}
