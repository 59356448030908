import React from "react";

import {
  Button,
  Card,
  CardContent,
  Collapse,
  Grid,
  TextField,
  Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { orange, indigo } from "@material-ui/core/colors";

import * as common from "jeoparty-common";

type ContestantsProps = {
  contestants?: common.Contestants;
  currentClue?: common.Clue;
  modifyScore: (teamName: string, value: number) => void;
  setScore: (teamName: string, value: number) => void;
  removeContestant: (teamName: string) => void;
  isAdmin: boolean;
  buzzedInTeam?: string;
  myTeam?: string;
  teamInControl?: string;
};

type EditScoreProps = {
  setScore: (teamName: string, value: number) => void;
  team: string;
};

const useStyles = makeStyles(() => ({
  buzzedInCard: {
    border: "2px solid",
    borderColor: orange[500],
    borderRadius: 6,
  },
  inControlCard: {
    border: "2px solid",
    borderColor: indigo[400],
    borderRadius: 6,
  },
  card: {},
}));

export default function Contestants({
  contestants,
  currentClue,
  modifyScore,
  setScore,
  removeContestant,
  isAdmin,
  buzzedInTeam,
  myTeam,
  teamInControl,
}: ContestantsProps) {
  const [expanded, setExpanded] = React.useState(false);
  const styles = useStyles();

  if (!contestants) return null;

  const teamNames = Object.keys(contestants);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  return (
    <>
      {/* <Typography variant="h5">Contestants</Typography> */}
      <Grid container spacing={2}>
        {teamNames.map((name) => {
          const team = contestants[name];
          const players = team.players;
          // const canSeeFinalWager = (team.finalJeopardyWager !== common.NO_FINAL_JEOPARDY_WAGER) && (isAdmin || isMe) // TODO show wagers after everyone bets
          type CardPropsType = {
            outline?: boolean;
            color?: string;
            inverse?: boolean;
          };

          let style = styles.card;
          if (name === teamInControl) {
            style = styles.inControlCard;
          }
          if (name === buzzedInTeam) {
            style = styles.buzzedInCard;
          }

          return (
            <Grid item xs={6} sm={4} md={2} key={name}>
              <Card key={name} className={style} id={name}>
                <CardContent>
                  <Typography variant="h6" color="primary">
                    {name}
                  </Typography>
                  {players.length > 1 && (
                    <Typography variant="h6">{players.join(", ")}</Typography>
                  )}
                  <Typography variant="body1" component="p">
                    {team.score} points
                  </Typography>
                  {team.dailyDoubleWager !== common.NO_DAILY_DOUBLE_WAGER && (
                    <p>Wager {team.dailyDoubleWager}</p>
                  )}
                  {team.buzzInTime !== common.NO_BUZZ_IN_TIME && (
                    <p>Buzz in {team.buzzInTime / 1000}s</p>
                  )}
                  {/* {name === buzzedInTeam && <Chip label="Buzzed" color="primary"/>} */}
                  {/* {name === teamInControl && <Chip label="In control" color="secondary"/>} */}
                </CardContent>
                {isAdmin && (
                  <Collapse in={expanded} timeout="auto" unmountOnExit>
                    <CardContent>
                      <TextField
                        id="text-input-id"
                        label="Score"
                        variant="outlined"
                        onChange={(e: any) => {
                          if (e.target.value) {
                            setScore(name, Number(e.target.value));
                          }
                        }}
                      />
                      {isAdmin && (
                        <Button onClick={() => removeContestant(name)}>
                          Remove
                        </Button>
                      )}
                    </CardContent>
                  </Collapse>
                )}
              </Card>
            </Grid>
          );
        })}
        {isAdmin && (
          <Grid item xs={1}>
            <Button
              onClick={handleExpandClick}
              aria-expanded={expanded}
              aria-label="edit contestants"
            >
              Edit
            </Button>
          </Grid>
        )}
      </Grid>
    </>
  );
}
